*{
  margin:0;
  padding:0;
  max-width:100%;

}

body {
  background: linear-gradient(rgba(255,255,255,.7), rgba(255,255,255,.7)), url("./shared/images/background.png");
	background-size:100%;
}



@font-face {
  font-family: 'ItalicEmpty';
  src: local('ItalicEmpty'), url(./shared/fonts/Gobold\ Hollow\ Bold\ Italic.otf) format('truetype');
  font-weight: bold;
  font-style: bold;
}
@font-face {
  font-family: 'ItalicBold';
  src: local('ItalicBold'), url(./shared/fonts/Gobold\ Bold\ Italic.otf) format('truetype');
  font-weight: bold;
  font-style: bold;
}
h1{
  color: white;
    font-family: "ItalicEmpty";
    font-size: 39px;
    margin:0;
    padding:1%;
}
h2{
  font-family: "ItalicBold";
}
p{
  font-family: "ItalicBold";
}
a{
  color:black;
  text-decoration: none;
}

.video{
  width: 100vw;
  display: flex;
  justify-content: center;
  margin:auto;
  max-height: 99vh;
  
  
}
